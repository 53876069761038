<template>
  <van-nav-bar
    :title="title"
    right-text="创建"
    @click="onCreate"
  />
  <div class="pirce-total">
    分账累计总和：{{totalAmount || 0}} 元
  </div>
  <form action="/">
    <van-search v-model="searchKey" placeholder="搜索" @clear="onClear" @search="onSearch" />
  </form>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 7px 0;">
          <small>员工id： {{ item.staffId }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>手机号： {{ item.user.phone }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>姓名： {{ item.name }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>注册： {{ item.createTime }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>累计分账：&yen;{{ item.user.profit }}</small>
        </p>
        <p style="margin:0 0 7px 0;">
          <small>余额：&yen;{{ item.user.balance }}</small>
        </p>
        <van-button type="primary" :to="{ name: 'StaffEdit', query: { staffId: item.staffId } }">修改</van-button>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      form: {
        show: false,
        row: {}
      },
      row: {},
      searchKey: '',
      storeId: Cookies.get('storeId'),
      totalAmount: 0
    })
    const onCreate = () => {
      useRouter.push({
        name: 'StaffCreate'
      })
    }
    const onClear = () => {
      state.searchKey = ''
      reset()
    }
    const onSearch = (value) => {
      state.searchKey = value
      reset()
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
      getData()
    }
    const getData = () => {
      post('/staff.list', {
        pageNum: state.page,
        storeId: state.storeId,
        phone: state.searchKey
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
        state.totalAmount = res.data.totalAmount
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onSearch,
      onClear,
      onCreate
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
.pirce-total{
  width: 100%;
  line-height: 80rpx;
  color: var(--van-nav-bar-title-text-color);
  font-weight: var(--van-font-weight-bold);
  font-size: var(--van-nav-bar-title-font-size);
  background-color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
